import {
  metals,
  miscellaneous as miscellaneousMaterials,
  stones,
  woods,
} from '../../attributes/material';

import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';
import type { Size } from '../../attributes';

// -- Config -------------------------------------------------------------------

/**
 * Loot size lookup defining the number of small items which can fit into a
 * container or furnishing. 1 is equal to a single small loot item.
 */
export const capacities: Partial<Record<Size, number>> = {
  /* eslint-disable perfectionist/sort-objects */
  tiny: 0.5,
  small: 1,
  medium: 5,
  large: 10,
  /* eslint-enable perfectionist/sort-objects */
};

/**
 * Item size lookup defining the amount of space a loot item requires. 1 is
 * equal to a single small item.
 */
export const itemSizeSpace: Partial<Record<Size, number>> = {
  /* eslint-disable perfectionist/sort-objects */
  tiny: 0.5,
  small: 1,
  /* eslint-enable perfectionist/sort-objects */
};

const defaults: LootConfigDefaults = {
  category: 'containers',
  isContainer: true,
  rarity: 'common',
  size: 'small',
};

const variants = [ ...metals, ...miscellaneousMaterials, ...stones, ...woods ];

const containers: Record<string, Partial<LootConfig> | null> = {
  Backpack: { size: 'medium' },
  'Barrel, large': { size: 'large' },
  'Barrel, medium': { size: 'medium' },
  'Barrel, small': null,
  'Basket, large': { size: 'medium' },
  'Basket, small': { size: 'small' },
  'Belt pouch, large': { },
  'Belt pouch, small': { size: 'tiny' },
  'Bottle, glass': { size: 'tiny' },
  Bowl: { variants },
  'Box, large': { size: 'large', variants },
  'Box, medium': { size: 'medium', variants },
  'Box, small': { variants },
  Bucket: null,
  'Case, map or scroll': null,
  'Chest, large': { size: 'large', variants },
  'Chest, medium': { size: 'medium', variants },
  'Chest, small': { variants },
  Crate: null,
  'Glass case': { size: 'medium' },
  Pouch: { size: 'tiny' },
  Sack: null,
  Tankard: { rarity: 'abundant', size: 'tiny' },
  Wagon: { size: 'large' },
};

const configs: LootConfigIncomplete[] = Object.entries(containers).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
