import type { MatrixHistoryEntry } from './';

// -- Types --------------------------------------------------------------------

interface MatrixHistoryAddResult {
  canRedo: boolean;
  canUndo: boolean;
  historyEntries: MatrixHistoryEntry[];
  historyIndex: number;
}

interface MatrixHistoryResult {
  canRedo: boolean;
  canUndo: boolean;
  entry: MatrixHistoryEntry;
  historyIndex: number;
}

// -- Public Functions ---------------------------------------------------------

/** Adds an entry to the history stack, discarding entries after the index. */
export function addHistoryEntry(
  entries: MatrixHistoryEntry[],
  index: number,
  entry: MatrixHistoryEntry
): MatrixHistoryAddResult {
  const newEntries: MatrixHistoryEntry[] = [ ...entries.slice(0, index + 1), entry ];
  const historyIndex = newEntries.length - 1;

  return {
    ...getHistoryChecks(newEntries, historyIndex),
    historyEntries: newEntries,
    historyIndex,
  };
}

/** Moves the index forward one step in history. */
export function redoHistory(
  entries: MatrixHistoryEntry[],
  index: number
): MatrixHistoryResult {
  const { canRedo } = getHistoryChecks(entries, index);

  if (!canRedo) {
    throw new TypeError('Cannot redo when the history index is at the end of the history stack in redoHistory()');
  }

  const historyIndex = index + 1;

  return {
    ...getHistoryChecks(entries, historyIndex),
    entry: entries[historyIndex],
    historyIndex,
  };
}

/** Replaces the most recent history entry. */
export function replaceHistoryEntry(
  entries: MatrixHistoryEntry[],
  index: number,
  entry: MatrixHistoryEntry
): MatrixHistoryAddResult {
  const newEntries: MatrixHistoryEntry[] = [ ...entries.slice(0, index), entry ];

  return {
    ...getHistoryChecks(newEntries, index),
    historyEntries: newEntries,
    historyIndex: index,
  };
}

/** Moves the index back one step in history. */
export function undoHistory(
  entries: MatrixHistoryEntry[],
  index: number
): MatrixHistoryResult {
  const { canUndo } = getHistoryChecks(entries, index);

  if (!canUndo) {
    throw new TypeError('Cannot undo when the history index is at the beginning of the history stack in undoHistory()');
  }

  const historyIndex = index - 1;

  return {
    ...getHistoryChecks(entries, historyIndex),
    entry: entries[historyIndex],
    historyIndex,
  };
}

// -- Getters ----------------------------------------------------------------

/** Whether the history stack can be moved to the next or previous step. */
function getHistoryChecks(
  entries: MatrixHistoryEntry[],
  index: number
) {
  return {
    canRedo: index < (entries.length - 1),
    canUndo: index > 0,
  };
}
