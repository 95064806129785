import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'rings',
  isMagic: true,
};

const rings: Record<string, Partial<LootConfig> | null> = {
  'Ring of Animal Influence': { rarity: 'rare' },
  'Ring of Djinni Summoning': { rarity: 'legendary' },
  'Ring of Elemental Command': { rarity: 'legendary', variants: [ 'Air', 'Earth', 'Fire', 'Water' ] },
  'Ring of Evasion': { rarity: 'rare' },
  'Ring of Feather Falling': { rarity: 'rare' },
  'Ring of Free Action': { rarity: 'rare' },
  'Ring of Invisibility': { rarity: 'legendary' },
  'Ring of Jumping': { rarity: 'uncommon' },
  'Ring of Mind Shielding': { rarity: 'uncommon' },
  'Ring of Protection': { rarity: 'rare' },
  'Ring of Regeneration': { rarity: 'veryRare' },
  'Ring of Resistance': { rarity: 'rare', variants: [ 'Acid', 'Cold', 'Fire', 'Force', 'Lightning', 'Necrotic', 'Poison', 'Psychic', 'Radiant', 'Thunder' ] },
  'Ring of Shooting Stars': { rarity: 'veryRare' },
  'Ring of Spell Storing': { rarity: 'rare' },
  'Ring of Spell Turning': { rarity: 'legendary' },
  'Ring of Swimming': { rarity: 'uncommon' },
  'Ring of Telekinesis': { rarity: 'veryRare' },
  'Ring of the Ram': { rarity: 'rare' },
  'Ring of Three Wishes': { rarity: 'legendary' },
  'Ring of Warmth': { rarity: 'uncommon' },
  'Ring of Water Walking': { rarity: 'uncommon' },
  'Ring of X-ray Vision': { rarity: 'rare' },
};

const configs: LootConfigIncomplete[] = Object.entries(rings).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
