import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useId } from 'react';

import WidowFix from '../Display/WidowFix';

import styles from './AlertDialog.module.css';

import type { Breakpoint } from '@mui/material';

// -- Public Component ---------------------------------------------------------

/**
 * Renders an alert dialog.
 */
export default function AlertDialog({
  buttonSize = 'small',
  cancelLabel = 'Cancel',
  children,
  confirmLabel = 'Ok',
  isOpen,
  isQuickDismiss = false,
  maxWidth = 'xs',
  onCancel,
  onConfirm,
  title,
}: {
  buttonSize?: 'medium' | 'small';
  cancelLabel?: string;
  children?: React.ReactNode;
  confirmLabel?: string;
  isOpen: boolean;
  isQuickDismiss?: boolean;
  maxWidth?: Breakpoint;
  onCancel?: () => void;
  onConfirm: () => void;
  title: string;
}) {
  const id = useId();
  const titleId = `alert-dialog-title-${id}`;
  const descId = `alert-dialog-desc-${id}`;

  return (
    <Dialog
      aria-describedby={children ? descId : undefined}
      aria-labelledby={titleId}
      className={styles.dialog}
      maxWidth={maxWidth}
      onClose={isQuickDismiss ? (onCancel ?? onConfirm) : undefined}
      open={isOpen}
    >

      <DialogTitle id={titleId}>
        <WidowFix>
          {title}
        </WidowFix>
      </DialogTitle>

      {children &&
        <DialogContent>
          <DialogContentText
            component="div"
            id={descId}
            textAlign="center"
          >
            {children}
          </DialogContentText>
        </DialogContent>
      }

      <DialogActions
        className={styles.actionButtons}
        data-button-size={buttonSize}
      >
        {onCancel &&
          <Button
            onClick={onCancel}
            size="small"
            variant="outlined"
          >
            {cancelLabel}
          </Button>
        }

        <Button
          autoFocus
          onClick={onConfirm}
          size="small"
          variant="contained"
        >
          {confirmLabel}
        </Button>
      </DialogActions>

    </Dialog>
  );
}
