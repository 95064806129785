import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'coins',
  maxCount: 100,
};

const coins: Record<string, Partial<LootConfig> | null> = {
  /* eslint-disable perfectionist/sort-objects */
  'Copper piece': { rarity: 'common' },
  'Silver piece': { rarity: 'uncommon' },
  'Electrum piece': { rarity: 'exotic' },
  'Gold piece': { rarity: 'rare' },
  'Platinum piece': { rarity: 'exotic' },
  Unidentifiable: { rarity: 'exotic' },
  /* eslint-enable perfectionist/sort-objects */
};

const configs: LootConfigIncomplete[] = Object.entries(coins).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
