import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'potions',
  isMagic: true,
  rarity: 'rare',
};

const potions: Record<string, Partial<LootConfig> | null> = {
  'Oil of Etherealness': { rarity: 'rare' },
  'Oil of Sharpness': { rarity: 'veryRare' },
  'Oil of Slipperiness': { rarity: 'uncommon' },
  'Philter of Love': { rarity: 'uncommon' },
  'Potion of Animal Friendship': { rarity: 'uncommon' },
  'Potion of Clairvoyance': { rarity: 'rare' },
  'Potion of Climbing': { rarity: 'common' },
  'Potion of Cloud Giant Strength': { rarity: 'veryRare' },
  'Potion of Diminution': { rarity: 'rare' },
  'Potion of Fire Giant Strength': { rarity: 'rare' },
  'Potion of Flying': { rarity: 'veryRare' },
  'Potion of Frost Giant Strength': { rarity: 'rare' },
  'Potion of Gaseous Form': { rarity: 'rare' },
  'Potion of Greater Healing': { rarity: 'uncommon' },
  'Potion of Growth': { rarity: 'uncommon' },
  'Potion of Healing': { rarity: 'common' },
  'Potion of Heroism': { rarity: 'rare' },
  'Potion of Hill Giant Strength': { rarity: 'uncommon' },
  'Potion of Invisibility': { rarity: 'veryRare' },
  'Potion of Mind Reading': { rarity: 'rare' },
  'Potion of Poison': { rarity: 'uncommon' },
  'Potion of Resistance': { rarity: 'uncommon', variants: [ 'Acid', 'Cold', 'Fire', 'Force', 'Lightning', 'Necrotic', 'Poison', 'Psychic', 'Radiant', 'Thunder' ] },
  'Potion of Speed': { rarity: 'veryRare' },
  'Potion of Stone Giant Strength': { rarity: 'rare' },
  'Potion of Storm Giant Strength': { rarity: 'legendary' },
  'Potion of Superior Healing': { rarity: 'rare' },
  'Potion of Supreme Healing': { rarity: 'veryRare' },
  'Potion of Water Breathing': { rarity: 'uncommon' },
};

const configs: LootConfigIncomplete[] = Object.entries(potions).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
