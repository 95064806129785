import { Typography } from '@mui/material';

import Layout from '../../components/Layout';
import Metadata from '../Utility/Metadata';

import styles from './StaticPage.module.css';

import type { AppPaths } from '../../config/path';

// -- Public Component ---------------------------------------------------------

/**
 * Renders boilerplate for a static page.
 */
export default function StaticPage({
  browserTitle,
  children,
  metaDescription,
  path,
  title,
}: {
  browserTitle?: string;
  children: React.ReactNode;
  metaDescription: string;
  path: AppPaths;
  title: string;
}) {
  return (
    <>
      <Metadata
        description={metaDescription}
        path={path}
        title={browserTitle ?? title}
      />

      <Layout>
        <section className={styles.content}>
          <Typography
            marginBottom={2}
            textAlign="center"
            variant="h1"
          >
            {title}
          </Typography>

          {children}
        </section>
      </Layout>
    </>
  );
}
