import { createTheme } from '@mui/material/styles';

import { breakpoint } from './';
import palette from './color';
import { defaultFontFamily, titleFontFamily } from './font';

// -- Theme Config -------------------------------------------------------------

/**
 * Material UI custom theme config.
 *
 * @see https://zenoo.github.io/mui-theme-creator/
 */
export default createTheme({
  components: {
    MuiAccordion: {
      // TODO fix top border radius on map editor option accordion
      styleOverrides: {
        root: {
          '&:last-child': {
            borderBottomWidth: '1px',
          },
          background: 'none',
          borderColor: palette.divider,
          borderStyle: 'solid',
          borderWidth: '1px 0 0 0',
        },
      },
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderColor: palette.divider,
          borderStyle: 'solid',
          borderWidth: '1px 0 0 0',
          padding: '1rem 0',
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
          '& svg': {
            height: '1.5rem',
            width: '1.5rem',
          },
          backgroundColor: palette.carbon,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          '&:hover': {
            backgroundColor: palette.primary.light,
          },
          borderColor: palette.primary.light,
          borderStyle: 'solid',
          borderWidth: 1,
          fontWeight: 700,
        },
        containedSizeMedium: {
          letterSpacing: '0.025rem',
        },
        sizeMedium: {
          letterSpacing: '0.05rem',
        },
        sizeSmall: {
          fontSize: '0.75rem',
          lineHeight: '1.5rem',
          minWidth: 0,
          padding: '0 0.5rem',
        },
        text: {
          paddingLeft: '1rem',
          paddingRight: '1rem',
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        html: {
          WebkitFontSmoothing: 'auto',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          background: palette.background.paper,
          border: 1,
          borderColor: palette.divider,
          borderStyle: 'solid',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          padding: '0.5rem 1.5rem 1.75rem 1.5rem',
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          // h4 equivalent
          fontSize: '1.5rem',
          letterSpacing: '0.05rem',
          lineHeight: 1.3,
          paddingBottom: '1rem',
          paddingTop: '1.5rem',
          textAlign: 'center',
        },
      },
    },

    MuiFormControl: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          marginTop: '0.5rem',
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '0.5rem 0.875rem',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingBottom: 10,
          paddingTop: 10,
        },
        notchedOutline: {
          transition: 'border-color 200ms ease',
        },
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary.main,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
          },
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '44px',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          [`@media (width >= ${breakpoint}px)`]: {
            '&:first-of-type': {
              borderTopLeftRadius: '0.25rem',
            },
            '&:last-of-type': {
              borderTopRightRadius: '0.25rem',
              paddingRight: '3rem',
            },
          },
          backgroundColor: palette.carbon,
          fontWeight: 700,
        },
        root: {
          borderBottomColor: palette.divider,
          fontSize: '1rem',
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            border: 0,
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '44px',
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        autoComplete: 'off',
        InputLabelProps: { shrink: true },
      },
    },

    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          '.MuiToggleButtonGroup-groupedVertical:not(:first-of-type)': {
            marginTop: '0.25rem',
          },
          '& .MuiToggleButtonGroup-grouped': {
            '&:first-of-type': {
              borderRadius: 4,
            },
            '&:not(:first-of-type)': {
              borderRadius: 4,
            },
            '&.Mui-disabled': {
              border: 0,
            },
            border: 0,
            padding: 0,
            transitionDuration: '200ms',
            transitionProperty: 'background',
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          '& .MuiTooltip-arrow': {
            color: palette.info.main,
          },
          backgroundColor: palette.info.main,
          fontSize: '0.875rem',
          paddingBottom: 6,
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 6,
        },
      },
    },
  },

  cssVariables: {
    cssVarPrefix: '',
  },

  palette,

  spacing: (factor: number) => `${factor / 2}rem`,

  typography: {
    fontFamily: defaultFontFamily,
    h1: {
      [`@media (width < ${breakpoint}px)`]: {
        fontSize: '2rem',
      },
      fontFamily: titleFontFamily,
      fontSize: '3rem',
      fontWeight: 400,
      letterSpacing: '0.05rem',
    },
    h2: {
      [`@media (width < ${breakpoint}px)`]: {
        fontSize: '1.5rem',
      },
      fontFamily: titleFontFamily,
      fontSize: '2.125rem',
      fontWeight: 400,
      letterSpacing: '0.05rem',
    },
    h3: {
      fontFamily: titleFontFamily,
      fontSize: '1.75rem',
      fontWeight: 400,
      letterSpacing: '0.05rem',
    },
    h4: {
      fontFamily: titleFontFamily,
      fontSize: '1.5rem',
      fontWeight: 400,
      letterSpacing: '0.05rem',
    },
    // Don't use `<h5>` or `<h6>`, they aren't styled and are unnecessary.
  },
});
