import { AREA, DETAIL } from './';
import { analyzeArea } from './analyze';
import {
  cloneMatrix,
  createBlankMatrix,
  getMatrixDimensions,
  getMaxRectangle,
  iterateMatrix,
} from './utility';

import type {
  CoordinatesKey,
  Details,
  MatrixImmutable,
  Regions,
} from './';

// -- Types --------------------------------------------------------------------

/**
 * Debug matrix options.
 */
export type Debug = 'areas'
  | 'coordinates'
  | 'details'
  | 'info'
  | 'labels'
  | 'values'
  | undefined;

/**
 * Immutable matrix of arbitrary strings or numbers used for matrix debugging.
 */
export type DebugMatrix = MatrixImmutable | readonly (readonly string[])[];

// -- Config ---------------------------------------------------------

/**
 * Serialized key of the console debug output, used to avoid redundant console
 * output on each render.
 */
let debugOutputKey = '';

/**
 * The previously requested debug option, used to clear the debug output key
 * when the debug option changes.
 */
let previousDebug: Debug;

// -- Public Functions ---------------------------------------------------------

/**
 * Returns a matrix for debugging.
 */
export function getDebugMatrix(
  matrix: MatrixImmutable,
  regions: Regions,
  details: Details,
  debug?: Debug
): DebugMatrix | undefined {
  if (!debug) {
    return;
  }

  if (previousDebug !== debug) {
    debugOutputKey = '';
  }

  previousDebug = debug;

  switch (debug) {
    case 'areas':
      return getAreaDebugMatrix(matrix, regions);

    case 'coordinates':
      return iterateMatrix(matrix, (_, [ x, y ]) => `${x},${y}`);

    case 'details':
      return getDetailDebugMatrix(matrix, details);

    case 'info':
      logMatrixInfo(regions, details);
      return;

    case 'labels':
      return getLabelDebugMatrix(matrix, regions);

    case 'values':
      return cloneMatrix(matrix);
  }

  throw new TypeError(`Invalid debug option "${debug as string}" in getDebugMatrix()`);
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns an area debug matrix.
 */
function getAreaDebugMatrix(matrix: MatrixImmutable, regions: Regions): DebugMatrix {
  const dimensions = getMatrixDimensions(matrix);
  const areaHeatMapMatrix = createBlankMatrix(dimensions);

  for (const { coordinates } of regions.values()) {
    const { heatGroups } = analyzeArea([ ...coordinates.values() ]);

    for (const [ heatValue, heatCoordinates ] of heatGroups) {
      for (const [ x, y ] of heatCoordinates.values()) {
        areaHeatMapMatrix[x][y] = heatValue;
      }
    }
  }

  return areaHeatMapMatrix;
}

/**
 * Returns a detail debug matrix.
 */
function getDetailDebugMatrix(matrix: MatrixImmutable, details: Details): DebugMatrix {
  const dimensions = getMatrixDimensions(matrix);
  const detailMatrix = createBlankMatrix(dimensions);
  const legendInfo: Map<DETAIL, number> = new Map();

  let legendKey = 0;

  for (const { coordinates, type } of details.values()) {
    const [ x, y ] = coordinates;
    let key = legendInfo.get(type);

    if (!key) {
      legendKey++;
      legendInfo.set(type, legendKey);
      key = legendKey;
    }

    detailMatrix[x][y] = key;
  }

  if (legendInfo.size && debugOutputKey !== [ ...legendInfo.entries() ].join()) {
    debugOutputKey = [ ...legendInfo.entries() ].join();
    const info = [ ...legendInfo ].map(([ type, key ]) => `${key}: ${type}`).join('\n');
    console.log(info); // eslint-disable-line no-console
  }

  return detailMatrix;
}

/**
 * Returns a label debug matrix.
 */
function getLabelDebugMatrix(matrix: MatrixImmutable, regions: Regions): DebugMatrix {
  const dimensions = getMatrixDimensions(matrix);
  const labelMatrix = createBlankMatrix(dimensions);

  for (const { coordinates, id } of regions.values()) {
    const rect = getMaxRectangle(coordinates);

    if (rect) {
      const { height, width, x, y } = rect;

      for (let cellX = x; cellX < x + width; cellX++) {
        for (let cellY = y; cellY < y + height; cellY++) {
          labelMatrix[cellX][cellY] = id;
        }
      }
    }
  }

  return labelMatrix;
}

/**
 * Logs matrix info.
 */
function logMatrixInfo(regions: Regions, details: Details): void {
  const info: string[] = [];

  for (const [ id, { coordinates, type }] of regions) {
    const regionType = type in AREA ? 'Area' : 'Connection';
    const detailCounts: Map<DETAIL, number> = new Map();

    for (const [ x, y ] of coordinates.values()) {
      const key: CoordinatesKey = `${x},${y}`;
      const detail = details.get(key);

      if (detail) {
        const count = detailCounts.get(detail.type) ?? 0;
        detailCounts.set(detail.type, count + 1);
      }
    }

    let entry = `${regionType}: ${id}\n`;
    entry += `Type: ${type}\n`;
    entry += `Size: ${coordinates.size}\n`;

    if (type in AREA) {
      const detailInfo = detailCounts.size
        ? [ ...detailCounts ].map(([ detailType, count ]) => `${detailType} (${count})`).join(', ')
        : 'None';

      entry += `Details: ${detailInfo}\n`;
    }

    info.push(entry);
  }

  console.log(info.join('\n')); // eslint-disable-line no-console
}
