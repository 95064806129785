import { useCallback, useState } from 'react';

import useResizeObserver from './useResizeObserver';

// -- Types --------------------------------------------------------------------

interface Dimensions {
  height: number;
  width: number;
}

type Result<T> = Dimensions & {
  ref: React.RefObject<T>;
};

// -- Public Hook --------------------------------------------------------------

/**
 * Observers dimensions of the element assigned to the returned ref and returns
 * updated dimensions if/when the element's size changes.
 */
export default function useDimensionsObserver<T extends HTMLElement>(): Result<T> {
  const [
    stageDimensions,
    setStageDimensions,
  ] = useState<Dimensions>({ height: 0, width: 0 });

  const onResize = useCallback((target: T) => {
    const { height, width } = target.getBoundingClientRect();
    setStageDimensions({ height, width });
  }, []);

  const ref = useResizeObserver(onResize);

  return {
    ...stageDimensions,
    ref,
  };
}
