import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';
import type { Rarity } from '../../attributes';

// -- Types --------------------------------------------------------------------

type GemEntry = Omit<LootConfigIncomplete, 'category'>;
type LootEntries = Record<string, Partial<LootConfig> | null>;

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'treasure',
  rarity: 'rare',
};

const gemDefaults: Pick<LootConfig, 'maxCount' | 'size'> = {
  maxCount: 5,
  size: 'tiny',
};

const artObjects: LootEntries = {

  // -- 25 GP art objects ------------------------------------------------------

  ...makeArtObjectEntries({
    'Black velvet mask stitched with silver thread': { size: 'small' },
    'Carved bone statuette': { size: 'small' },
    'Cloth-of-gold vestments': { size: 'small' },
    'Copper chalice with silver filigree': { size: 'small' },
    'Embroidered silk handkerchief': { size: 'tiny' },
    'Gold locket with a painted portrait inside': { size: 'tiny' },
    'Pair of engraved bone dice': { size: 'tiny' },
    'Silver ewer': { size: 'small' },
    'Small gold bracelet': { size: 'tiny' },
    'Small mirror set in a painted wooden frame': { size: 'small' },
  } as LootEntries, {
    rarity: 'uncommon',
    value: '25 GP',
  }),

  // -- 250 GP art objects -----------------------------------------------------

  ...makeArtObjectEntries({
    'Box of turquoise animal figurines': { size: 'medium' },
    'Brass mug with jade inlay': { size: 'small' },
    'Bronze crown': { size: 'small' },
    'Carved ivory statuette': { size: 'small' },
    'Gold bird cage with electrum filigree': { size: 'medium' },
    'Gold ring set with bloodstones': { size: 'small' },
    'Large gold bracelet': { size: 'small' },
    'Large well-made tapestry': { size: 'large' },
    'Silk robe with gold embroidery': { size: 'medium' },
    'Silver necklace with a gemstone pendant': { size: 'small' },
  } as LootEntries, {
    rarity: 'rare',
    value: '250 GP',
  }),

  // -- 750 GP art objects -----------------------------------------------------

  ...makeArtObjectEntries({
    'Bottle stopper cork embossed with gold leaf and set with amethysts': { size: 'tiny' },
    'Carved harp of exotic wood with ivory inlay and zircon gems': { size: 'medium' },
    'Ceremonial electrum dagger with a black pearl in the pommel': { size: 'small' },
    'Gold dragon comb set with red garnets as eyes': { size: 'small' },
    'Obsidian statuette with gold fittings and inlay': { size: 'small' },
    'Painted gold war mask': { size: 'small' },
    'Silver-plated steel longsword with jet set in hilt': { size: 'medium' },
    'Silver and gold brooch': { size: 'tiny' },
    'Silver chalice set with moonstones': { size: 'small' },
    'Small gold idol': { size: 'small' },
  } as LootEntries, {
    rarity: 'veryRare',
    value: '750 GP',
  }),

  // -- 2,500 GP art objects -----------------------------------------------------

  ...makeArtObjectEntries({
    'A necklace string of small pink pearls': { size: 'tiny' },
    'Embroidered glove set with jewel chips': { size: 'small' },
    'Embroidered silk and velvet mantle set with numerous moonstones': { size: 'medium' },
    'Eye patch with a mock eye set in blue sapphire and moonstone': { size: 'tiny' },
    'Fine gold chain set with a fire opal': { size: 'tiny' },
    'Gold circlet set with four aquamarines': { size: 'tiny' },
    'Gold music box': { size: 'small' },
    'Jeweled anklet': { size: 'tiny' },
    'Old masterpiece painting': { size: 'large' },
    'Platinum bracelet set with a sapphire': { size: 'tiny' },
  } as LootEntries, {
    rarity: 'exotic',
    value: '2,500 GP',
  }),

  // -- 7,500 GP art objects -----------------------------------------------------

  ...makeArtObjectEntries({
    'Bejeweled ivory drinking horn with gold filigree': { size: 'small' },
    'Gold cup set with emeralds': { size: 'small' },
    'Gold jewelry box with platinum filigree': { size: 'small' },
    'Jade game board with solid gold playing pieces': { size: 'medium' },
    'Jeweled gold crown': { size: 'small' },
    'Jeweled platinum ring': { size: 'tiny' },
    "Painted gold child's sarcophagus": { size: 'large' },
    'Small gold statuette set with rubies': { size: 'small' },
  } as LootEntries, {
    rarity: 'legendary',
    value: '7,500 GP',
  }),
};

const gemstones: GemEntry[] = [

  // -- 10 GP gemstones --------------------------------------------------------

  ...[
    'Azurite (opaque mottled deep blue)',
    'Banded agate (translucent striped brown, blue, white, or red)',
    'Blue quartz (transparent pale blue)',
    'Eye agate (translucent circles of gray, white, brown, blue, or green)',
    'Hematite (opaque gray-black)',
    'Lapis lazuli (opaque light and dark blue with yellow flecks)',
    'Malachite (opaque striated light and dark green)',
    'Moss agate (translucent pink or yellow-white with mossy gray or green markings)',
    'Obsidian (opaque black)',
    'Rhodochrosite (opaque light pink)',
    'Tiger eye (translucent brown with golden center)',
    'Turquoise (opaque light blue-green)',
  ].map((name): GemEntry => ({
    name: `${name}, 10 GP`,
    rarity: 'uncommon',
  })),

  // -- 50 GP gemstones --------------------------------------------------------

  ...[
    'Bloodstone (opaque dark gray with red flecks)',
    'Carnelian (opaque orange to red-brown)',
    'Chalcedony (opaque white)',
    'Chrysoprase (translucent green)',
    'Citrine (transparent pale yellow-brown)',
    'Jasper (opaque blue, black, or brown)',
    'Moonstone (translucent white with pale blue glow)',
    'Onyx (opaque bands of black and white, or pure black or white)',
    'Quartz (transparent white, smoky gray, or yellow)',
    'Sardonyx (opaque bands of red and white)',
    'Star rose quartz (translucent rosy stone with white star-shaped center)',
    'Zircon (transparent pale blue-green)',
  ].map((name): GemEntry => ({
    name: `${name}, 50 GP`,
    rarity: 'rare',
  })),

  // -- 100 GP gemstones --------------------------------------------------------

  ...[
    'Amber (transparent watery gold to rich gold)',
    'Amethyst (transparent deep purple)',
    'Chrysoberyl (transparent yellow-green to pale green)',
    'Coral (opaque crimson)',
    'Garnet (transparent red, brown-green, or violet)',
    'Jade (translucent light green, deep green, or white)',
    'Jet (opaque deep black)',
    'Pearl (opaque lustrous white, yellow, or pink)',
    'Spinel (transparent red, red-brown, or deep green)',
    'Tourmaline (transparent pale green, blue, brown, or red)',
  ].map((name): GemEntry => ({
    name: `${name}, 100 GP`,
    rarity: 'rare',
  })),

  // -- 500 GP gemstones --------------------------------------------------------

  ...[
    'Alexandrite (transparent dark green)',
    'Aquamarine (transparent pale blue-green)',
    'Black pearl (opaque pure black)',
    'Blue spinel (transparent deep blue)',
    'Peridot (transparent rich olive green)',
    'Topaz (transparent golden yellow)',
  ].map((name): GemEntry => ({
    name: `${name}, 500 GP`,
    rarity: 'veryRare',
  })),

  // -- 1,000 GP gemstones --------------------------------------------------------

  ...[
    'Black opal (translucent dark green with black mottling and golden flecks)',
    'Blue sapphire (transparent blue-white to medium blue)',
    'Emerald (transparent deep bright green)',
    'Fire opal (translucent fiery red)',
    'Opal (translucent pale blue with green and golden mottling)',
    'Star ruby (translucent ruby with white star-shaped center)',
    'Star sapphire (translucent blue sapphire with white star-shaped center)',
    'Yellow sapphire (transparent fiery yellow or yellow-green)',
  ].map((name): GemEntry => ({
    maxCount: 3,
    name: `${name}, 1,000 GP`,
    rarity: 'exotic',
  })),

  // -- 5,000 GP gemstones --------------------------------------------------------

  ...[
    'Black sapphire (translucent lustrous black with glowing highlights)',
    'Diamond (transparent blue-white, canary, pink, brown, or blue)',
    'Jacinth (transparent fiery orange)',
    'Ruby (transparent clear red to deep crimson)',
  ].map((name): GemEntry => ({
    maxCount: 1,
    name: `${name}, 5,000 GP`,
    rarity: 'legendary',
  })),

];

const configs: LootConfigIncomplete[] = [

  // -- Art objects ----------------------------------------------------------

  ...Object.entries(artObjects).map(([ name, config ]) => ({
    ...defaults,
    ...config,
    name,
  })),

  // -- Gemstones ------------------------------------------------------------

  ...Object.values(gemstones).map((config) => ({
    ...defaults,
    ...gemDefaults,
    ...config,
  })),

];

export default configs;

// -- Private Functions --------------------------------------------------------

/**
 * Applies value and rarity to an object of Art Object `LootEntries` and returns
 * an updated `LootEntries` object.
 */
function makeArtObjectEntries(
  loot: LootEntries,
  { rarity, value }: { rarity: Rarity; value: string }
): LootEntries {
  return Object.entries(loot).reduce((entries, [ name, config ]) => {
    const fullName = `${name}, ${value}`;

    entries[fullName] = {
      ...config,
      name: fullName,
      rarity,
    } as Partial<LootConfig>;

    return entries;
  }, {} as LootEntries);
}
