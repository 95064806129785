import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'wondrous',
  isMagic: true,
};

const wondrous: Record<string, Partial<LootConfig> | null> = {
  'Amulet of Health': { rarity: 'rare' },
  'Amulet of Proof against Detection and Location': { rarity: 'uncommon' },
  'Amulet of the Planes': { rarity: 'veryRare' },
  'Apparatus of the Crab': { rarity: 'legendary' },
  'Bag of Beans': { rarity: 'rare' },
  'Bag of Devouring': { rarity: 'veryRare' },
  'Bag of Holding': { rarity: 'uncommon' },
  'Bag of Tricks': { rarity: 'uncommon', variants: [ 'Gray', 'Rust', 'Tan' ] },
  'Bead of Force': { rarity: 'rare' },
  'Belt of Cloud Giant Strength': { rarity: 'legendary' },
  'Belt of Dwarvenkind': { rarity: 'rare' },
  'Belt of Fire Giant Strength': { rarity: 'veryRare' },
  'Belt of Frost Giant Strength': { rarity: 'veryRare' },
  'Belt of Hill Giant Strength': { rarity: 'rare' },
  'Belt of Stone Giant Strength': { rarity: 'veryRare' },
  'Belt of Storm Giant Strength': { rarity: 'legendary' },
  'Boots of Elvenkind': { rarity: 'uncommon' },
  'Boots of Levitation': { rarity: 'rare' },
  'Boots of Speed': { rarity: 'rare' },
  'Boots of Striding and Springing': { rarity: 'uncommon' },
  'Boots of the Winterlands': { rarity: 'uncommon' },
  'Bowl of Commanding Water Elementals': { rarity: 'rare' },
  'Bracers of Archery': { rarity: 'uncommon' },
  'Bracers of Defense': { rarity: 'rare' },
  'Brass Horn of Valhalla': { rarity: 'rare' },
  'Brazier of Commanding Fire Elementals': { rarity: 'rare' },
  'Bronze Griffon Figurine of Wondrous Power': { rarity: 'rare' },
  'Bronze Horn of Valhalla': { rarity: 'veryRare' },
  'Brooch of Shielding': { rarity: 'uncommon' },
  'Broom of Flying': { rarity: 'uncommon' },
  'Candle of Invocation': { rarity: 'veryRare' },
  'Cape of the Mountebank': { rarity: 'rare' },
  'Carpet of Flying': { rarity: 'veryRare', variants: [ '3 ft. × 5 ft.', '4 ft. × 6 ft.', '5 ft. × 7 ft.', '6 ft. × 9 ft.' ] },
  'Censer of Controlling Air Elementals': { rarity: 'rare' },
  'Chime of Opening': { rarity: 'rare' },
  'Circlet of Blasting': { rarity: 'uncommon' },
  'Cloak of Arachnida': { rarity: 'veryRare' },
  'Cloak of Displacement': { rarity: 'rare' },
  'Cloak of Elvenkind': { rarity: 'uncommon' },
  'Cloak of Protection': { rarity: 'uncommon' },
  'Cloak of the Bat': { rarity: 'rare' },
  'Cloak of the Manta Ray': { rarity: 'uncommon' },
  'Crystal Ball': { rarity: 'veryRare' },
  'Crystal Ball of Mind Reading': { rarity: 'legendary' },
  'Crystal Ball of Telepathy': { rarity: 'legendary' },
  'Crystal Ball of True Seeing': { rarity: 'legendary' },
  'Cube of Force': { rarity: 'rare' },
  'Cubic Gate': { rarity: 'legendary' },
  'Decanter of Endless Water': { rarity: 'uncommon' },
  'Deck of Illusions': { rarity: 'uncommon' },
  'Deck of Many Things': { rarity: 'legendary' },
  'Dimensional Shackles': { rarity: 'rare' },
  'Dust of Disappearance': { rarity: 'uncommon' },
  'Dust of Dryness': { rarity: 'uncommon' },
  'Dust of Sneezing and Choking': { rarity: 'uncommon' },
  'Ebony Fly Figurine of Wondrous Power': { rarity: 'rare' },
  'Efficient Quiver': { rarity: 'uncommon' },
  'Efreeti Bottle': { rarity: 'veryRare' },
  'Elemental Gem': { rarity: 'uncommon', variants: [ 'Air', 'Earth', 'Fire', 'Water' ] },
  'Eversmoking Bottle': { rarity: 'uncommon' },
  'Eyes of Charming': { rarity: 'uncommon' },
  'Eyes of Minute Seeing': { rarity: 'uncommon' },
  'Eyes of the Eagle': { rarity: 'uncommon' },
  'Feather Token': { rarity: 'rare', variants: [ 'Anchor', 'Bird', 'Fan', 'Swan Boat', 'Tree', 'Whip' ] },
  'Folding Boat': { rarity: 'rare' },
  'Gauntlets of Ogre Power': { rarity: 'uncommon' },
  'Gem of Brightness': { rarity: 'uncommon' },
  'Gem of Seeing': { rarity: 'rare' },
  'Gloves of Missile Snaring': { rarity: 'uncommon' },
  'Gloves of Swimming and Climbing': { rarity: 'uncommon' },
  'Goggles of Night': { rarity: 'uncommon' },
  'Golden Lions Figurine of Wondrous Power': { rarity: 'rare' },
  'Handy Haversack': { rarity: 'rare' },
  'Hat of Disguise': { rarity: 'uncommon' },
  'Headband of Intellect': { rarity: 'uncommon' },
  'Helm of Brilliance': { rarity: 'veryRare' },
  'Helm of Comprehending Languages': { rarity: 'uncommon' },
  'Helm of Telepathy': { rarity: 'uncommon' },
  'Helm of Teleportation': { rarity: 'rare' },
  'Horn of Blasting': { rarity: 'rare' },
  'Horseshoes of a Zephyr': { rarity: 'veryRare' },
  'Horseshoes of Speed': { rarity: 'rare' },
  'Instant Fortress': { rarity: 'rare' },
  'Ioun Stone of Absorption': { rarity: 'veryRare' },
  'Ioun Stone of Agility': { rarity: 'veryRare' },
  'Ioun Stone of Awareness': { rarity: 'rare' },
  'Ioun Stone of Fortitude': { rarity: 'veryRare' },
  'Ioun Stone of Greater Absorption': { rarity: 'legendary' },
  'Ioun Stone of Insight': { rarity: 'veryRare' },
  'Ioun Stone of Intellect': { rarity: 'veryRare' },
  'Ioun Stone of Leadership': { rarity: 'veryRare' },
  'Ioun Stone of Mastery': { rarity: 'legendary' },
  'Ioun Stone of Protection': { rarity: 'rare' },
  'Ioun Stone of Regeneration': { rarity: 'legendary' },
  'Ioun Stone of Reserve': { rarity: 'rare' },
  'Ioun Stone of Strength': { rarity: 'veryRare' },
  'Ioun Stone of Sustenance': { rarity: 'rare' },
  'Iron Bands of Binding': { rarity: 'rare' },
  'Iron Flask': { rarity: 'legendary' },
  'Iron Horn of Valhalla': { rarity: 'legendary' },
  'Ivory Goats Figurine of Wondrous Power': { rarity: 'rare' },
  'Lantern of Revealing': { rarity: 'uncommon' },
  'Mantle of Spell Resistance': { rarity: 'rare' },
  'Manual of Bodily Health': { rarity: 'veryRare' },
  'Manual of Gainful Exercise': { rarity: 'veryRare' },
  'Manual of Golems': { rarity: 'veryRare', variants: [ 'Clay', 'Flesh', 'Iron', 'Stone' ] },
  'Manual of Quickness of Action': { rarity: 'veryRare' },
  'Marble Elephant Figurine of Wondrous Power': { rarity: 'rare' },
  'Marvelous Pigments': { rarity: 'veryRare' },
  'Medallion of Thoughts': { rarity: 'uncommon' },
  'Mirror of Life Trapping': { rarity: 'veryRare' },
  'Necklace of Adaptation': { rarity: 'uncommon' },
  'Necklace of Fireballs': { rarity: 'rare' },
  'Necklace of Prayer Beads': { rarity: 'rare' },
  'Obsidian Steed Figurine of Wondrous Power': { rarity: 'veryRare' },
  'Onyx Dog Figurine of Wondrous Power': { rarity: 'rare' },
  'Pearl of Power': { rarity: 'uncommon' },
  'Periapt of Health': { rarity: 'uncommon' },
  'Periapt of Proof against Poison': { rarity: 'rare' },
  'Periapt of Wound Closure': { rarity: 'uncommon' },
  'Pipes of Haunting': { rarity: 'uncommon' },
  'Pipes of the Sewers': { rarity: 'uncommon' },
  'Portable Hole': { rarity: 'rare' },
  'Restorative Ointment': { rarity: 'uncommon' },
  'Robe of Eyes': { rarity: 'rare' },
  'Robe of Scintillating Colors': { rarity: 'veryRare' },
  'Robe of Stars': { rarity: 'veryRare' },
  'Robe of the Archmagi': { rarity: 'legendary' },
  'Robe of Useful Items': { rarity: 'uncommon' },
  'Rope of Climbing': { rarity: 'uncommon' },
  'Rope of Entanglement': { rarity: 'rare' },
  'Scarab of Protection': { rarity: 'legendary' },
  'Serpentine Owl Figurine of Wondrous Power': { rarity: 'rare' },
  'Silver Horn of Valhalla': { rarity: 'rare' },
  'Silver Raven Figurine of Wondrous Power': { rarity: 'uncommon' },
  'Slippers of Spider Climbing': { rarity: 'uncommon' },
  'Sovereign Glue': { rarity: 'legendary' },
  'Sphere of Annihilation': { rarity: 'legendary' },
  'Stone of Controlling Earth Elementals': { rarity: 'rare' },
  'Stone of Good Luck (Luckstone)': { rarity: 'uncommon' },
  'Talisman of Pure Good': { rarity: 'legendary' },
  'Talisman of the Sphere': { rarity: 'legendary' },
  'Talisman of Ultimate Evil': { rarity: 'legendary' },
  'Tome of Clear Thought': { rarity: 'veryRare' },
  'Tome of Leadership and Influence': { rarity: 'veryRare' },
  'Tome of Understanding': { rarity: 'veryRare' },
  'Universal Solvent': { rarity: 'legendary' },
  'Well of Many Worlds': { rarity: 'legendary' },
  'Wind Fan': { rarity: 'uncommon' },
  'Winged Boots': { rarity: 'uncommon' },
  'Wings of Flying': { rarity: 'rare' },
};

const configs: LootConfigIncomplete[] = Object.entries(wondrous).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
