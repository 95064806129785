import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'clothing',
  rarity: 'abundant',
};

const clothing: Record<string, Partial<LootConfig> | null> = {
  Belt: null,
  Boots: { variants: [ 'riding', 'soft', 'combat' ] },
  Breaches: null,
  Brooch: null,
  Cap: null,
  Cape: { variants: [ 'cotton', 'canvas', 'fur', 'silk' ] },
  'Clothes, common': null,
  'Clothes, costume': { rarity: 'uncommon' },
  'Clothes, fine': { rarity: 'rare' },
  "Clothes, traveler's": { rarity: 'uncommon' },
  Gloves: null,
  Gown: null,
  Hat: null,
  Hose: null,
  Jacket: { variants: [ 'leather', 'fur', 'silk' ] },
  Mittens: null,
  Robes: { variants: [ 'common', 'embroidered' ] },
  Sandals: null,
  Sash: null,
  Shoes: null,
  Surcoat: null,
  Tunic: null,
  Vest: { variants: [ 'leather', 'fur', 'silk' ] },
};

const configs: LootConfigIncomplete[] = Object.entries(clothing).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
