import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'tack',
  rarity: 'uncommon',
};

const tack: Record<string, Partial<LootConfig> | null> = {
  Barding: { variants: [ 'chain', 'plage', 'scabb' ] },
  'Bit and bridle': null,
  Carriage: null,
  Cart: null,
  Chariot: null,
  Feed: null,
  Saddle: { variants: [ 'Exotic', 'Military', 'Pack', 'Riding' ] },
  Saddlebags: null,
};

const configs: LootConfigIncomplete[] = Object.entries(tack).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
