import { getOrSetMapEntry } from '..';
import { matrixDimensionsDefault } from '../../config/map';
import {
  AREA,
  CONNECTION,
  connectionsFlippable,
  DETAIL,
  directionsCardinal,
  DRAW_OPTION,
} from './';

import type {
  AdjacentCellMap,
  CellValue,
  ConnectionAreaDirections,
  ConnectionDirection,
  Coordinates,
  CoordinatesKey,
  CoordinatesMap,
  Details,
  Dimensions,
  Direction,
  DirectionCardinal,
  DirectionOrdinal,
  MatrixClone,
  MatrixHistoryEntry,
  MatrixImmutable,
  MatrixMutable,
  Rect,
  Region,
  RegionIdPool,
  RegionIdPoolState,
  Regions,
} from './';
import type { DebugMatrix } from './debug';

// -- Config -------------------------------------------------------------------

/** Coordinate offsets, keyed by direction, of orthogonally adjacent cells. */
export const orthogonalOffsetMap: Map<DirectionCardinal, Coordinates> = new Map([
  [ 'north', [ 0, -1 ]],
  [ 'east', [ 1, 0 ]],
  [ 'south', [ 0, 1 ]],
  [ 'west', [ -1, 0 ]],
]);

/** Coordinate offsets, keyed by direction, of diagonally adjacent cells. */
export const diagonalOffsetMap: Map<DirectionOrdinal, Coordinates> = new Map([
  [ 'north-east', [ 1, -1 ]],
  [ 'south-east', [ 1, 1 ]],
  [ 'south-west', [ -1, 1 ]],
  [ 'north-west', [ -1, -1 ]],
]);

/**
 * Coordinate offsets, keyed by direction, of orthogonal and diagonal
 * adjacent cells.
 */
export const offsetMap = new Map([ ...orthogonalOffsetMap, ...diagonalOffsetMap ]);

/** Coordinate offsets of orthogonally adjacent cells. */
export const orthogonalOffsets = [ ...orthogonalOffsetMap.values() ];

/** Coordinate offsets of diagonally adjacent cells. */
export const diagonalOffsets = [ ...diagonalOffsetMap.values() ];

/** Coordinate offsets of orthogonal and diagonal adjacent cells. */
export const offsets = [ ...offsetMap.values() ];

// -- Public Functions ---------------------------------------------------------

/**
 * Clones an immutable matrix and returns a mutable matrix.
 */
export function cloneMatrix(matrix: MatrixImmutable | MatrixMutable): MatrixImmutable | MatrixMutable {
  const mutableMatrix: MatrixMutable = [];

  for (const column of matrix) {
    mutableMatrix.push(column.slice());
  }

  return mutableMatrix;
}

/**
 * Returns a multi dimensional array of empty matrix cells for the given
 * dimensions.
 */
export function createBlankMatrix([ width, height ]: Dimensions): MatrixMutable {
  if (width < 1 || height < 1) {
    throw new RangeError(`Invalid matrix dimensions "${width} x ${height}" in createBlankMatrix()`);
  }

  return Array(width).fill(null).map(() => Array(height).fill(null) as CellValue[]) as MatrixMutable;
}

/**
 * Returns a map of cell values, keyed by direction, for each cell surrounding
 * the given cell, with a strategy defined by the options.
 */
export function getAdjacentCells(
  matrix: MatrixImmutable,
  [ x, y ]: Coordinates,
  {
    diagonals,
    find = 'all',
  }: {
    diagonals?: boolean;
    find?: CellValue | 'all' | 'area' | 'connection' | 'empty' | 'nonArea' | 'occupied';
  } = {}
): AdjacentCellMap {
  const adjacentCells: AdjacentCellMap = new Map();
  const adjacentOffsets = diagonals ? offsetMap : orthogonalOffsetMap;

  for (const [ direction, [ xOffset, yOffset ]] of adjacentOffsets) {
    const coordinates: Coordinates = [ x + xOffset, y + yOffset ];
    const [ adjacentX, adjacentY ] = coordinates;
    const value = matrix[adjacentX]?.[adjacentY];
    const isEmpty = value === null || value === undefined;
    const isAreaValue = isArea(value);
    const isConnectionValue = isConnection(value);

    if (
      find === 'all' ||
      (find === 'area' && isAreaValue) ||
      (find === 'connection' && isConnectionValue) ||
      (find === 'empty' && isEmpty) ||
      (find === 'nonArea' && !isAreaValue) ||
      (find === 'occupied' && (isAreaValue || isConnectionValue)) ||
      (find === value)
    ) {
      adjacentCells.set(direction, { coordinates, value });
    }
  }

  return adjacentCells;
}

/**
 * Returns a bounding rectangle for the given coordinates in matrix units.
 */
export function getBoundingRect(coordinates: Coordinates[]): Rect {
  const xCoords = [];
  const yCoords = [];

  for (const [ x, y ] of coordinates) {
    xCoords.push(x);
    yCoords.push(y);
  }

  const xMin = Math.min(...xCoords);
  const xMax = Math.max(...xCoords);
  const yMin = Math.min(...yCoords);
  const yMax = Math.max(...yCoords);

  return {
    height: yMax - yMin + 1,
    width: xMax - xMin + 1,
    x: xMin,
    y: yMin,
  };
}

/**
 * Returns an array of orthogonally adjacent cell groups, grouped using a flood
 * fill algorithm, sorted by group size with the largest group first.
 */
export function getCellGroups(coordinatesMap: CoordinatesMap): Coordinates[][] {

  // Create a graph of the regions's coordinates.

  const graph: Map<CoordinatesKey, Set<CoordinatesKey>> = new Map();

  for (const [ x, y ] of coordinatesMap.values()) {
    const adjacentCells = [];

    for (const [ xOffset, yOffset ] of orthogonalOffsets) {
      const adjacentX = x + xOffset;
      const adjacentY = y + yOffset;

      if (coordinatesMap.has(`${adjacentX},${adjacentY}`)) {
        adjacentCells.push([ adjacentX, adjacentY ]);
      }
    }

    const vertexKey: CoordinatesKey = `${x},${y}`;
    const vertex = getOrSetMapEntry(graph, vertexKey, new Set());

    for (const [ neighborX, neighborY ] of adjacentCells) {
      const neighborKey: CoordinatesKey = `${neighborX},${neighborY}`;
      const neighbor = getOrSetMapEntry(graph, neighborKey, new Set());

      vertex.add(neighborKey);
      neighbor.add(vertexKey);
    }
  }

  // Group adjacent cells into separate regions.

  const groups: Map<number, Coordinates[]> = new Map();
  const marked: Set<CoordinatesKey> = new Set();

  /** Recursively groups cells. */
  function recursivelyGroupCells(vertex: CoordinatesKey, id: number) {
    marked.add(vertex);

    const [ x, y ] = vertex.split(',').map(Number);

    getOrSetMapEntry(groups, id, []).push([ x, y ]);

    const entry = graph.get(vertex);

    if (entry) {
      for (const key of entry) {
        if (!marked.has(key)) {
          recursivelyGroupCells(key, id);
        }
      }
    }
  }

  let groupId = 0;

  for (const vertex of graph.keys()) {
    if (!marked.has(vertex)) {
      // Start from an unmarked vertex
      recursivelyGroupCells(vertex, groupId);

      // All touching vertexes have been grouped, increment the group id for the
      // next recursion.
      groupId++;
    }
  }

  return [ ...groups.values() ].sort((a, b) => b.length - a.length);
}

/**
 * Creates a closure and returns functions for getting the next available area
 * and connection ids for the given matrix.
 */
export function getCellIdPool(
  matrix: MatrixImmutable,
  [ currentAreaId, currentConnectionId ]: RegionIdPoolState = [ 0, 0 ]
): RegionIdPool {
  // `null` is cast to `0` in `Math.max()` & `Math.min()`, so this is fine.
  const ids = new Set(matrix.flat()) as Set<number>;

  let areaId = Math.max(currentAreaId, ...ids);
  let connectionId = Math.min(currentConnectionId, ...ids);

  return {
    getAreaId: () => ++areaId,
    getConnectionId: () => --connectionId,
    getPoolState: () => [ areaId, connectionId ],
  };
}

/**
 * Returns a cell's value for the given matrix and coordinates, or throws an
 * error if the coordinates are outside the matrix's dimensions.
 */
export function getCellValue(
  matrix: MatrixImmutable,
  [ x, y ]: Coordinates
): CellValue {
  const value = matrix[x]?.[y];

  if (typeof value === 'undefined') {
    const [ width, height ] = getMatrixDimensions(matrix);
    throw new RangeError(`Cell at "${x},${y}" is outside matrix dimensions "${width} × ${height}" in getCellValue()`);
  }

  return value;
}

/**
 * Returns a cell's value for the given matrix and coordinates, or undefined if
 * the coordinates are outside the matrix's dimensions.
 */
export function getCellValueWeak(
  matrix: MatrixImmutable,
  [ x, y ]: Coordinates
): CellValue | undefined {
  return matrix[x]?.[y];
}

/**
 * Returns a matrix connection cell's area directions.
 */
export function getConnectionCellAreaDirections(
  matrix: MatrixImmutable,
  coordinates: Coordinates
): ConnectionAreaDirections {
  if (!isConnection(getCellValue(matrix, coordinates))) {
    throw new TypeError(`Invalid connection cell at "${coordinates.join()}" in getConnectionCellAreaDirections()`);
  }

  const adjacentCells = getAdjacentCells(matrix, coordinates, { find: 'area' });

  if (adjacentCells.size === 1) {
    const [[ direction1, { value: areaId1 }]] = [ ...adjacentCells ];

    return new Map([[
      direction1 as DirectionCardinal,
      areaId1 as number,
    ]]);
  }

  if (adjacentCells.size !== 2) {
    throw new TypeError(`Invalid connection at "${coordinates.join()}" in getConnectionCellAreaDirections()`);
  }

  const [
    [ direction1, { value: areaId1 }],
    [ direction2, { value: areaId2 }],
  ] = [ ...adjacentCells ];

  return new Map([
    [ direction1 as DirectionCardinal, areaId1 as number ],
    [ direction2 as DirectionCardinal, areaId2 as number ],
  ]);
}

/**
 * Returns a connection cell's connection direction.
 */
export function getConnectionCellDirection(
  matrix: MatrixImmutable,
  coordinates: Coordinates
): ConnectionDirection | null {
  if (isArea(getCellValueWeak(matrix, coordinates))) {
    return null;
  }

  const matrixDimensions = getMatrixDimensions(matrix);

  if (isBoundary(matrixDimensions, coordinates)) {
    return null;
  }

  const adjacentCells = getAdjacentCells(matrix, coordinates, { find: 'area' });

  if (adjacentCells.size === 1) {
    return [ ...adjacentCells.keys() ][0] as DirectionCardinal;
  }

  if (adjacentCells.size === 2) {
    if (adjacentCells.has('north') && adjacentCells.has('south')) {
      return 'north-south';
    }

    if (adjacentCells.has('east') && adjacentCells.has('west')) {
      return 'east-west';
    }
  }

  return null;
}

/**
 * Returns a connection's computed connection direction.
 */
export function getConnectionDirection(
  areaDirections: ConnectionAreaDirections
): ConnectionDirection {
  if (areaDirections.size === 1) {
    return [ ...areaDirections.keys() ][0];
  }

  const directionSet = new Set(areaDirections.keys());

  if (directionSet.size !== 2) {
    throw new TypeError(`Invalid connection directions "${[ ...directionSet ].join(', ')}" in getConnectionDirection()`);
  }

  if (directionSet.has('north') && directionSet.has('south')) {
    return 'north-south';
  }

  if (directionSet.has('east') && directionSet.has('west')) {
    return 'east-west';
  }

  throw new TypeError(`Conflicting connection directions "${[ ...directionSet ].join(', ')}" in getConnectionDirection()`);
}

/**
 * Returns coordinates for the given direction.
 */
export function getCoordinatesInDirection(
  [ x, y ]: Coordinates,
  direction: Direction,
  distance = 1
): Coordinates {
  switch (direction) {
    case 'north':
      return [ x, y - distance ];

    case 'east':
      return [ x + distance, y ];

    case 'south':
      return [ x, y + distance ];

    case 'west':
      return [ x - distance, y ];

    case 'north-east':
      return [ x + distance, y - distance ];

    case 'south-east':
      return [ x + distance, y + distance ];

    case 'south-west':
      return [ x - distance, y + distance ];

    case 'north-west':
      return [ x - distance, y - distance ];
  }

  throw new TypeError(`Invalid direction "${direction as string}" in getCoordinatesInDirection()`);
}

/**
 * Returns draw coordinates for the draw option.
 */
export function getDrawCoordinates(
  coordinates: Coordinates[],
  drawOption: DRAW_OPTION
): Coordinates[] {
  if (!coordinates.length) {
    return coordinates;
  }

  if (drawOption === DRAW_OPTION.Rectangle) {
    const [ start, end ] = coordinates;
    return getRectangleCoordinates(start, end);
  }

  if (drawOption === DRAW_OPTION.Line) {
    const [ start, end ] = coordinates;
    return getLineCoordinates(start, end);
  }

  // Free draw.
  return coordinates;
}

/**
 * Returns cell coordinates in a line for the given start and end coordinates.
 */
export function getLineCoordinates(start: Coordinates, end?: Coordinates): Coordinates[] {
  if (!end) {
    return [ start ];
  }

  const [ x1, y1 ] = start;
  const [ x2, y2 ] = end;

  const coordinates: Coordinates[] = [];

  if (isHorizontalLine(start, end)) {

    // Horizontal line

    if (x1 < x2) {
      // Horizontal line east.
      for (let x = x1; x <= x2; x++) {
        coordinates.push([ x, y1 ]);
      }
    } else {
      // Horizontal line west.
      for (let x = x1; x >= x2; x--) {
        coordinates.push([ x, y1 ]);
      }
    }

    return coordinates;
  }

  // Vertical line

  if (y1 < y2) {
    // Vertical line south.
    for (let y = y1; y <= y2; y++) {
      coordinates.push([ x1, y ]);
    }
  } else {
    // Vertical line north.
    for (let y = y1; y >= y2; y--) {
      coordinates.push([ x1, y ]);
    }
  }

  return coordinates;
}

/**
 * Returns dimensions for the given matrix.
 */
export function getMatrixDimensions(matrix: MatrixImmutable | MatrixMutable): Dimensions {
  const width = matrix.length;
  const height = matrix[0].length;

  return [ width, height ];
}

/**
 * Returns the largest rectangular area within the given coordinates map as a
 * rectangle object in matrix units.
 *
 * Adapted from:
 *
 *   - https://stackoverflow.com/questions/7245/puzzle-find-largest-rectangle-maximal-rectangle-problem/20039017#20039017
 *   - https://drdobbs.com/database/the-maximal-rectangle-problem/184410529
 */
export function getMaxRectangle(
  coordinatesMap: CoordinatesMap,
  { minDimensions = 1 }: { minDimensions?: number } = {}
): Rect | undefined {
  let rect: (Rect & { area: number }) | undefined;

  const xCoords: number[] = [];
  const yCoords: number[] = [];

  for (const [ x, y ] of coordinatesMap.values()) {
    xCoords.push(x);
    yCoords.push(y);
  }

  const xMin = Math.min(...xCoords);
  const xMax = Math.max(...xCoords);
  const yMin = Math.min(...yCoords);
  const yMax = Math.max(...yCoords);

  const depthLookup: Map<number, number> = new Map();

  for (let y = yMin; y < (yMax + 1); y++) {
    const ranges: { depth: number; startX: number }[] = [];

    for (let x = xMin; x < (xMax + 2); x++) {
      const depth = coordinatesMap.has(`${x},${y}`)
        ? (depthLookup.get(x) ?? 0) + 1
        : 0;

      depthLookup.set(x, depth);

      if (!ranges.length || ranges[ranges.length - 1].depth < depth) {
        ranges.push({ depth, startX: x });
        continue;
      }

      let i = ranges.length - 1;

      for (i; i >= 0 && ranges[i].depth >= depth; i--) {
        const { depth: height, startX } = ranges[i];
        const width = x - startX;
        const area = width * height;

        if (area > (rect?.area ?? 0) && width >= minDimensions && height >= minDimensions) {
          rect = {
            area,
            height,
            width,
            x: startX,
            y: y + 1 - height,
          };
        }
      }

      ranges.splice(i + 2);
      ranges[i + 1].depth = depth;
    }
  }

  if (!rect) {
    return;
  }

  const { area, ...maxRect } = rect;

  return maxRect;
}

/**
 * Returns the region id which should be used when merging regions.
 */
export function getMergeId(regions: Regions, adjacentCellIds: Set<number>): number {
  const cellIds = [ ...adjacentCellIds ];

  const hasArea = cellIds.some((id) => isArea(id));

  const mergeId = cellIds.reduce((largestRegion, id) => {
    const { coordinates, type } = getRegion(regions, id);

    if (hasArea && type in CONNECTION) {
      return largestRegion;
    }

    if (coordinates.size > largestRegion.size) {
      return { id, size: coordinates.size };
    }

    return largestRegion;
  }, { id: 0, size: 0 }).id;

  if (mergeId === 0) {
    throw new TypeError('Invalid merge id "0" in getMergeId()');
  }

  return mergeId;
}

/**
 * Returns the opposite direction.
 */
export function getOppositeDirection(direction: Direction): Direction {
  switch (direction) {
    case 'north':
    case 'east':
    case 'south':
    case 'west':
      return getOppositeDirectionCardinal(direction);

    case 'north-east':
      return 'south-west';

    case 'south-east':
      return 'north-west';

    case 'south-west':
      return 'north-east';

    case 'north-west':
      return 'south-east';
  }

  throw new TypeError(`Invalid direction "${direction as string}" in getOppositeDirection()`);
}

/**
 * Returns the opposite cardinal direction.
 */
export function getOppositeDirectionCardinal(direction: DirectionCardinal): DirectionCardinal {
  switch (direction) {
    case 'north':
      return 'south';

    case 'east':
      return 'west';

    case 'south':
      return 'north';

    case 'west':
      return 'east';
  }

  throw new TypeError(`Invalid direction "${direction as string}" in getOppositeDirectionCardinal()`);
}

/**
 * Returns cell coordinates in a rectangle for the given start and end
 * coordinates. Coordinates always start from the given start coordinates,
 * iterating cells by column, ending at the given end coordinates.
 */
export function getRectangleCoordinates(start: Coordinates, end?: Coordinates): Coordinates[] {
  if (!end) {
    return [ start ];
  }

  const [ x1, y1 ] = start;
  const [ x2, y2 ] = end;

  const coordinates: Coordinates[] = [];

  const isRight = x1 <= x2;
  const isDown = y1 <= y2;

  // Right & down

  if (isRight && isDown) {
    for (let x = x1; x <= x2; x++) {
      for (let y = y1; y <= y2; y++) {
        coordinates.push([ x, y ]);
      }
    }

    return coordinates;
  }

  // Right & up

  if (isRight) {
    for (let x = x1; x <= x2; x++) {
      for (let y = y1; y >= y2; y--) {
        coordinates.push([ x, y ]);
      }
    }

    return coordinates;
  }

  // Left & down

  if (isDown) {
    for (let x = x1; x >= x2; x--) {
      for (let y = y1; y <= y2; y++) {
        coordinates.push([ x, y ]);
      }
    }

    return coordinates;
  }

  // Left & up

  for (let x = x1; x >= x2; x--) {
    for (let y = y1; y >= y2; y--) {
      coordinates.push([ x, y ]);
    }
  }

  return coordinates;
}

/**
 * Returns region properties, or throws an error if the region does not exist.
 */
export function getRegion(regions: Regions, id: CellValue): Region {
  if (typeof id !== 'number') {
    throw new TypeError(`Invalid region id "${id}" in getRegion()`);
  }

  const region = regions.get(id);

  if (!region) {
    throw new TypeError(`Invalid region "${id}" in getRegion()`);
  }

  return region;
}

/**
 * Blindly deep copies a matrix instance's properties with no validation.
 *
 * Used for a slight performance boost when instantiating a new Matrix from an
 * existing Matrix, such as when calculating a draw or erase operation in
 * `useInteractiveMap()`.
 *
 * For Matrix property instantiation with validation, use `initializeMatrix()`
 * instead.
 */
export function initializeClone({ details, matrix, regions }: MatrixClone): MatrixClone {
  const clonedRegions: Regions = new Map();

  for (const [ id, region ] of regions) {
    clonedRegions.set(id, {
      ...region,
      coordinates: new Map(region.coordinates),
    });
  }

  const clonedDetails = new Map(details);

  for (const [ key, detail ] of details) {
    clonedDetails.set(key, {
      ...detail,
      coordinates: [ ...detail.coordinates ],
    });
  }

  return {
    details: clonedDetails,
    matrix: cloneMatrix(matrix) as MatrixMutable,
    regions: clonedRegions,
  };
}

/**
 * Validates initial matrix state and returns a mutable matrix, dimensions, and
 * a map of region properties.
 */
export function initializeMatrix(entry?: MatrixHistoryEntry): {
  details: Details;
  matrix: MatrixMutable;
  regions: Regions;
} {
  if (!entry) {
    return {
      details: new Map(),
      matrix: createBlankMatrix(matrixDimensionsDefault),
      regions: new Map(),
    };
  }

  const dimensions = entry.dimensions;
  const matrix = createBlankMatrix(dimensions);
  const regions: Regions = new Map();
  const details: Details = new Map();

  for (const region of entry.regions ?? []) {
    const { coordinates, id, type } = region;

    if (typeof id !== 'number' || id === 0 || !Number.isInteger(id)) {
      throw new TypeError(`Invalid area id "${id}" in map data.`);
    }

    if (!(type in AREA) && !(type in CONNECTION)) {
      throw new TypeError(`Invalid region type "${type}" in map data.`);
    }

    const groupCoordinates: Region['coordinates'] = new Map();

    for (const cellCoordinates of coordinates) {
      const [ x, y ] = cellCoordinates;

      if (isBoundary(dimensions, cellCoordinates)) {
        // Discard cells on the matrix's boundary.
        continue;
      }

      const cellValue = getCellValueWeak(matrix, cellCoordinates);

      if (typeof cellValue === 'undefined') {
        // Discard cells outside the matrix's dimensions.
        continue;
      }

      if (cellValue !== null) {
        throw new TypeError(`Region id "${id}" overlaps region id "${cellValue}" at "${x},${y}" in map data.`);
      }

      matrix[x][y] = id;
      groupCoordinates.set(`${x},${y}`, cellCoordinates);
    }

    if (groupCoordinates.size) {
      regions.set(id, {
        ...region,
        coordinates: groupCoordinates,
      });
    }
  }

  for (const [ type, detail ] of Object.entries(entry.details ?? [])) {
    for (const { coordinates: cellCoordinates, rotation } of detail) {
      if (!(type in DETAIL)) {
        throw new TypeError(`Invalid detail type "${type}" in map data.`);
      }

      if (isBoundary(dimensions, cellCoordinates)) {
        // Discard detail on the matrix's boundary.
        continue;
      }

      const [ x, y ] = cellCoordinates;
      const coordinateKey: CoordinatesKey = `${x},${y}`;
      const cellValue = getCellValueWeak(matrix, cellCoordinates);

      if (typeof cellValue === 'undefined') {
        // Discard detail outside the matrix's dimensions.
        continue;
      }

      if (details.has(coordinateKey)) {
        throw new TypeError(`Detail "${type}" overlaps detail "${details.get(coordinateKey)?.type}" at "${x},${y}" in map data.`);
      }

      if (!isArea(cellValue)) {
        throw new TypeError(`Detail "${type}" at "${x},${y}" is not in an area in map data.`);
      }

      details.set(coordinateKey, {
        coordinates: cellCoordinates,
        rotation,
        type: type as DETAIL,
      });
    }
  }

  return {
    details,
    matrix,
    regions,
  };
}

/**
 * Whether a cell value is assigned to an area.
 */
export function isArea(value?: CellValue): value is number {
  return typeof value === 'number' && value > 0;
}

/**
 * Whether a cell's coordinates is on the edge of the matrix.
 */
export function isBoundary([ width, height ]: Dimensions, [ x, y ]: Coordinates): boolean {
  return x === 0 || y === 0 || x === (width - 1) || y === (height - 1);
}

/**
 * Whether a cell value is assigned to a connection.
 */
export function isConnection(value?: CellValue): value is number {
  return typeof value === 'number' && value < 0;
}

/**
 * Whether a connection can be flipped.
 */
export function isConnectionFlippable(type: CONNECTION, direction: ConnectionDirection) {
  if (!connectionsFlippable.has(type)) {
    // Non-flippable connection.
    return false;
  }

  if (type === CONNECTION.SecretDoor && directionsCardinal.has(direction as DirectionCardinal)) {
    // Exterior secret door connections cannot be flipped.
    return false;
  }

  return true;
}

/**
 * Whether the line between the start and end coordinates is horizontal.
 */
export function isHorizontalLine(start: Coordinates, end: Coordinates): boolean {
  const [ x1, y1 ] = start;
  const [ x2, y2 ] = end;

  const minX = Math.min(x1, x2);
  const maxX = Math.max(x1, x2);
  const minY = Math.min(y1, y2);
  const maxY = Math.max(y1, y2);

  return maxX - minX > maxY - minY;
}

/**
 * Iterates a multi dimensional array of matrix cells by column cell and returns
 * an array aggregated from the callback's return values.
 */
export function iterateMatrix<CallbackReturnType>(
  matrix: DebugMatrix | MatrixImmutable | MatrixMutable,
  callback: (cellValue: CellValue | string, coordinates: Coordinates) => CallbackReturnType
): CallbackReturnType[][] {
  return matrix.map((column, x) => column.map((value, y) => callback(value, [ x, y ])));
}

/**
 * Converts an array of coordinates into a map of coordinates, keyed by their
 * coordinates key.
 */
export function toCoordinatesMap(coordinates: Coordinates[]): CoordinatesMap {
  const coordinatesMap: CoordinatesMap = new Map();

  for (const cellCoordinates of coordinates) {
    const [ x, y ] = cellCoordinates;
    coordinatesMap.set(`${x},${y}`, cellCoordinates);
  }

  return coordinatesMap;
}
