import type { PaletteMode } from '@mui/material/styles/createPalette';

// -- Config -------------------------------------------------------------------

// TODO rename file to palette.ts

/** Application colors */
export const appColors = { // TODO replace access to default export
  background: '#0c0f0f', //
  backgroundMapCanvas: '#e1e1e1', // background.map
  divider: '#424242', //
  error: '#f44336', //
  info: '#276091', //
  neutral: '#9e9e9e',
  overlay: 'rgb(0 0 0 / 75%)', //
  paper: '#1a1a1a', //
  paperAccent: '#222424', // carbon
  primary: '#19cbda', //
  primaryLight: '#00def5', //
  secondary: '#ea1265', //
  success: '#12ea65', //
  textMuted: '#cccccc', //
  textPrimary: '#ffffff', //
  textSecondary: '#cfcfcf', //
  warning: '#f48836', //
};

const palette = {
  background: {
    default: '#0c0f0f',
    map: '#e1e1e1',
    paper: '#1a1a1a',
  },
  carbon: '#222424', // TODO move into background
  divider: '#424242',
  error: {
    main: '#f44336',
  },
  info: {
    main: '#276091',
  },
  mode: 'dark' as PaletteMode,
  overlay: 'rgb(0 0 0 / 75%)',
  primary: {
    light: '#00def5',
    main: '#19cbda',
  },
  secondary: {
    main: '#ea1265',
  },
  success: {
    main: '#12ea65',
  },
  text: {
    muted: '#cccccc',
    primary: '#ffffff', // TODO ever so slightly darker?
    secondary: '#cfcfcf',
  },
  warning: {
    main: '#f48836',
  },
};

export default palette;

/** Canvas UI colors */
export const canvasUiColors = {
  preview: appColors.primary,
};
