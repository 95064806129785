import { Group, Line, Rect as Rectangle } from 'react-konva';

import { cellPx, colors, detailPx } from '../../../config/map';
import { getDiceBag } from '../../../lib/dice';
import { DETAIL } from '../../../lib/matrix';

import type { Dice, ProbabilityTable } from '../../../lib/dice';
import type { Coordinates } from '../../../lib/matrix';

// -- Types --------------------------------------------------------------------

interface CrateInstructions {
  bound?: 1 | 2;
  isBoxed?: boolean;
  rotation: number;
  size: CrateSize;
  x: number;
  y: number;
}

type CrateAttributes = Omit<CrateInstructions, 'x' | 'y'>;

interface CrateProps {
  coordinates: Coordinates;
  seed: string;
}

type CrateSize = 'large' | 'small';

type Tidiness = 'chaos' | 'neat' | 'precise';

// -- Config -------------------------------------------------------------------

const minPerCell = 1;
const maxPerCell = 3;

const halfDetailPx = detailPx / 2;

const sizeSmall = cellPx / 3;
const sizeLarge = cellPx / 2;

const offsetSmall = (sizeSmall / 2);
const offsetLarge = (sizeLarge / 2);

const radiusSmall = (sizeSmall * Math.sqrt(2)) / 2;
const radiusLarge = (sizeLarge * Math.sqrt(2)) / 2;

const slatsSmallCount = 3;
const slatsLargeCount = 4;

const tidinessProbabilityTable: ProbabilityTable<Tidiness> = new Map([
  [ 'precise', 35 ],
  [ 'neat', 40 ],
  [ 'chaos', 25 ],
]);

// -- Public Component ---------------------------------------------------------

/**
 * Renders crates.
 *
 * TDL apply poission disc sampling to position crate clusters across multiples
 * cells.
 *
 * TODO tests
 */
export default function Crates(props: CrateProps) {
  const crates = useCrateInstructions(props); // TDL nest in component & memo

  const { coordinates: [ x, y ] } = props;
  const xPx = x * cellPx;
  const yPx = y * cellPx;

  return (
    <Group
      data-id={`detail-${DETAIL.Crates}`}
      data-theme="classic"
      x={xPx}
      y={yPx}
    >
      {crates.map((crateProps, i) => (
        <Crate {...crateProps} key={i} />
      ))}
    </Group>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders a single small crate.
 */
function Crate({ bound, isBoxed, rotation, size, x, y }: CrateInstructions) {
  const isLarge = size === 'large';
  const offset = isLarge ? offsetLarge : offsetSmall;
  const dimension = isLarge ? sizeLarge : sizeSmall;

  const slatCount = isLarge ? slatsLargeCount : slatsSmallCount;
  const slatSpacing = dimension / (slatCount + 1);
  const slatEdgeSize = slatSpacing - halfDetailPx;

  const slatPoints: [ x1: number, y1: number, x2: number, y2: number ][] = [];

  for (let i = 1; i <= slatCount; i++) {
    const y1 = slatSpacing * i;
    slatPoints.push([ 0, y1, 0 + dimension, y1 ]);
  }

  return (
    <Group
      offsetX={offset}
      offsetY={offset}
      rotation={rotation}
      x={x}
      y={y}
    >
      <Rectangle
        fill={colors.detail}
        height={dimension}
        stroke={colors.border}
        strokeWidth={detailPx}
        width={dimension}
      />

      {slatPoints.map((points, i) => (
        <Line
          key={i}
          points={points}
          stroke={colors.border}
          strokeWidth={detailPx}
        />
      ))}

      {isLarge && isBoxed &&
        <>
          {bound &&
            <Rectangle
              fill={colors.detail}
              height={slatSpacing}
              rotation={bound === 1 ? 45 : -45}
              stroke={colors.border}
              strokeWidth={detailPx}
              width={(sizeLarge - slatSpacing) * Math.sqrt(2)}
              x={bound === 1
                ? (slatSpacing - halfDetailPx)
                : halfDetailPx
              }
              y={bound === 1
                ? halfDetailPx
                : (sizeLarge - slatSpacing)
              }
            />
          }

          <Rectangle
            fill={colors.detail}
            height={dimension}
            stroke={colors.border}
            strokeWidth={detailPx}
            width={slatEdgeSize}
            x={0}
            y={0}
          />

          <Rectangle
            fill={colors.detail}
            height={dimension}
            stroke={colors.border}
            strokeWidth={detailPx}
            width={slatEdgeSize}
            x={dimension - slatEdgeSize}
            y={0}
          />
        </>
      }
    </Group>
  );
}

// -- Private Hooks ------------------------------------------------------------

/**
 * Returns instructions for crate placement, size, and rotation.
 */
function useCrateInstructions({
  coordinates: [ x, y ],
  seed,
}: CrateProps): CrateInstructions[] {
  const dice = getDiceBag({ seed: `${seed}.${x}.${y}` });

  const count = dice.roll(minPerCell, maxPerCell);

  const tidiness = dice.getProbabilityRoll(tidinessProbabilityTable)();
  const clusterRotation = count > 1 && dice.roll() ? getCrateRotation(dice, tidiness) : null;

  const attributes: CrateAttributes[] = [];

  for (let i = 0; i < count; i++) {
    const size = getCrateSize(dice, attributes[0]?.size);

    const rotation = clusterRotation ?? getCrateRotation(dice, tidiness);
    const crateAttributes: Omit<CrateInstructions, 'x' | 'y'> = { rotation, size };

    if (size === 'large' && dice.roll()) {
      crateAttributes.isBoxed = true;

      if (dice.roll()) {
        crateAttributes.bound = dice.roll() ? 1 : 2;
      }
    }

    attributes.push(crateAttributes);
  }

  const instructions = getCratePositions(dice, attributes, {
    clusterRotation,
  });

  return instructions;
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns crate offset.
 */
function getCrateInset(
  size: CrateInstructions['size'],
  rotation: CrateInstructions['rotation']
): number {
  if (rotation === 0 || rotation === 90) {
    // When a crate is not askew, use the position offset instead of the radius
    // to create a more natural appearance.
    return size === 'small' ? offsetSmall : offsetLarge;
  }

  return size === 'small' ? radiusSmall : radiusLarge;
}

/**
 * Returns crate position.
 */
function getCratePositions(
  dice: Dice,
  attributes: CrateAttributes[],
  {
    clusterRotation, // eslint-disable-line @typescript-eslint/no-unused-vars
  }: {
    clusterRotation: number | null;
  }
): CrateInstructions[] {
  const instructions: CrateInstructions[] = [];
  const [ firstCrate ] = attributes; // TDL multiple crates

  const inset = getCrateInset(firstCrate.size, firstCrate.rotation) + (detailPx * 2);

  const positionMin = inset;
  const positionMax = cellPx - inset;

  const x1 = dice.roll(positionMin, positionMax);
  const y1 = dice.roll(positionMin, positionMax);

  instructions.push({ ...firstCrate, x: x1, y: y1 });

  return instructions;
}

/**
 * Returns random crate size.
 */
function getCrateSize(dice: Dice, firstSize?: CrateSize): CrateSize {
  if (firstSize === 'large') {
    // Only allow the first crate in a cell to be large.
    return 'small';
  }

  return dice.roll() ? 'small' : 'large';
}

/**
 * Returns random crate rotation.
 */
function getCrateRotation(
  dice: Dice,
  tidiness: Tidiness
): CrateInstructions['rotation'] {
  switch (tidiness) {
    case 'precise':
      return dice.roll() ? 0 : 90;

    case 'neat':
      return dice.rollArrayItem([
        0,
        45,
        90,
        135,
        180,
        225,
        270,
        315,
      ]);

    case 'chaos':
      return dice.roll(0, 359);
  }
}
