import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'miscellaneous',
  rarity: 'common',
};

const squareFootageVariants = [ '1 square foot', '5 square feet', '10 square feet', '20 square foot' ];
const lengthVariants = [ '1 foot', '5 feet', '10 feet', '20 foot' ];

const miscellaneousItems: Record<string, Partial<LootConfig> | null> = {
  Bell: null,
  Bone: { variants: [ 'rib', 'pelvis', 'femur', 'leg', 'arm' ] },
  Bones: { maxCount: 10, size: 'tiny', variants: [ 'finger', 'foot', 'vertebrae' ] },
  'Bones, pile': { size: 'medium' },
  Boulder: { size: 'large' },
  Candle: { maxCount: 5, rarity: 'abundant' },
  Canvas: { variants: squareFootageVariants },
  'Cloth, bolt, common': { maxCount: 10, variants: squareFootageVariants },
  'Cloth, bolt, fine': { maxCount: 10, rarity: 'uncommon', variants: squareFootageVariants },
  'Cloth, cotton': { variants: squareFootageVariants },
  'Cloth, linen': { variants: squareFootageVariants },
  'Cloth, silk': { rarity: 'veryRare', variants: squareFootageVariants },
  Hide: { variants: [ 'wolf', 'bear', 'deer', 'rabbit', 'raccoon', 'beaver' ] },
  Incense: { rarity: 'rare' },
  Instrument: { rarity: 'exotic', variants: [ 'Bagpipes', 'Drum', 'Dulcimer', 'Flute', 'Lute', 'Lyre', 'Horn', 'Pan flute', 'Shawm', 'Viol' ] },
  'Iron, bar': { rarity: 'uncommon' },
  Manacles: null,
  Perfume: { variants: [ 'vial', 'bottle' ] },
  Rock: { size: 'medium' },
  Skull: null,
  'Stone chunk': { size: 'tiny', variants: [ 'Moonstone', 'Quartz', 'Onyx', 'Obsidian' ] },
  String: { variants: lengthVariants },
  Torch: null,
  Totem: null,
};

const configs: LootConfigIncomplete[] = Object.entries(miscellaneousItems).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
