import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Types --------------------------------------------------------------------

type LootEntries = Record<string, Partial<LootConfig> | null>;

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'mystical',
  rarity: 'rare',
};

const magicDefaults: Partial<LootConfig> = {
  isMagic: true,
};

const mystical: LootEntries = {
  'Arcane focus': { variants: [ 'crystal', 'orb', 'rod', 'staff', 'wand' ] },
  'Component pouch': { rarity: 'rare', size: 'tiny' },
  'Druidic focus': null,
  'Holy symbol': { variants: [ 'amulet', 'emblem', 'reliquary' ] },
  Spellbook: null,
  'Yew wand': null,
};

const magical: LootEntries = {
  'Immovable Rod': { rarity: 'uncommon' },
  'Rod of Absorption': { rarity: 'veryRare' },
  'Rod of Alertness': { rarity: 'veryRare' },
  'Rod of Lordly Might': { rarity: 'legendary' },
  'Rod of Rulership': { rarity: 'rare' },
  'Rod of Security': { rarity: 'veryRare' },
  'Spell Scroll (1st)': { rarity: 'common' },
  'Spell Scroll (2nd)': { rarity: 'uncommon' },
  'Spell Scroll (3rd)': { rarity: 'uncommon' },
  'Spell Scroll (4th)': { rarity: 'rare' },
  'Spell Scroll (5th)': { rarity: 'rare' },
  'Spell Scroll (6th)': { rarity: 'veryRare' },
  'Spell Scroll (7th)': { rarity: 'veryRare' },
  'Spell Scroll (8th)': { rarity: 'veryRare' },
  'Spell Scroll (9th)': { rarity: 'legendary' },
  'Spell Scroll (Cantrip)': { rarity: 'common' },
  'Staff of Charming': { rarity: 'rare' },
  'Staff of Fire': { rarity: 'veryRare' },
  'Staff of Frost': { rarity: 'veryRare' },
  'Staff of Healing': { rarity: 'rare' },
  'Staff of Power': { rarity: 'veryRare' },
  'Staff of Striking': { rarity: 'veryRare' },
  'Staff of Swarming Insects': { rarity: 'veryRare' },
  'Staff of the Magi': { rarity: 'legendary' },
  'Staff of the Python': { rarity: 'veryRare' },
  'Staff of the Woodlands': { rarity: 'rare' },
  'Staff of Thunder and Lightning': { rarity: 'veryRare' },
  'Staff of Withering': { rarity: 'rare' },
  'Wand of Binding': { rarity: 'rare' },
  'Wand of Enemy Detection': { rarity: 'rare' },
  'Wand of Fear': { rarity: 'rare' },
  'Wand of Fireballs': { rarity: 'rare' },
  'Wand of Lightning Bolts': { rarity: 'rare' },
  'Wand of Magic Detection': { rarity: 'uncommon' },
  'Wand of Magic Missiles': { rarity: 'uncommon' },
  'Wand of Paralysis': { rarity: 'rare' },
  'Wand of Polymorph': { rarity: 'veryRare' },
  'Wand of Secrets': { rarity: 'uncommon' },
  'Wand of the War Mage, +1': { rarity: 'uncommon' },
  'Wand of the War Mage, +2': { rarity: 'rare' },
  'Wand of the War Mage, +3': { rarity: 'veryRare' },
  'Wand of Web': { rarity: 'uncommon' },
  'Wand of Wonder': { rarity: 'rare' },
};

const configs: LootConfigIncomplete[] = [

  // -- Basic mystical ---------------------------------------------------------

  ...Object.entries(mystical).map(([ name, config ]) => ({
    ...defaults,
    ...config,
    name,
  })),

  // -- Magical mystical -------------------------------------------------------

  ...Object.entries(magical).map(([ name, config ]) => ({
    ...defaults,
    ...magicDefaults,
    ...config,
    name,
  })),

];

export default configs;
