import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'survival',
  rarity: 'uncommon',
};

const survivalEquipment: Record<string, Partial<LootConfig> | null> = {
  Bedroll: { rarity: 'common' },
  Blanket: { rarity: 'common' },
  "Climber's kit": null,
  Crampons: null,
  Firewood: { rarity: 'abundant' },
  Fishhook: null,
  'Fishing net': { variants: [ 'small', 'medium' ] },
  'Fishing net, large': { size: 'large' },
  'Fishing tackle': null,
  'Flint and steel': null,
  'Hunting trap': null,
  Piton: null,
  'Signal whistle': null,
  Tent: { variants: [ 'one-person', 'two-person', 'pavilion' ] },
  Tinderbox: null,
  Waterskin: null,
};

const configs: LootConfigIncomplete[] = Object.entries(survivalEquipment).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
