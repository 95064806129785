import { Button, Stack, Tooltip } from '@mui/material';
import { memo, useContext, useEffect, useState } from 'react';

import {
  Draw as DrawIcon,
  Map as MapIcon,
} from '../../components/Display/Icons';
import WarningBox from '../../components/Display/WarningBox';
import WidowFix from '../../components/Display/WidowFix';
import VisuallyHiddenFileInput from '../../components/Input/VisuallyHiddenFileInput';
import AlertDialog from '../../components/Interface/AlertDialog';
import BigJuicyButton from '../../components/Interface/BigJuicyButton';
import EmptyState from '../../components/Interface/EmptyState';
import Link from '../../components/Interface/Link';
import Layout from '../../components/Layout';
import useSidebar from '../../components/Layout/hooks/useSidebar';
import MapCanvas from '../../components/Map';
import DownloadStage from '../../components/Map/DownloadStage';
import useDownloadMap from '../../components/Map/hooks/useDownloadMap';
import useInteractiveMap from '../../components/Map/hooks/useInteractiveMap';
import useMapInfo from '../../components/Map/hooks/useMapInfo';
import DownloadOptions from '../../components/Map/Interface/DownloadOptions';
import StaticMapCanvas from '../../components/Map/StaticMap';
import Metadata from '../../components/Utility/Metadata';
import { UnsavedChangesContext } from '../../components/Utility/UnsavedChangesContextProvider';
import { appTitle, appVersion } from '../../config';
import { path } from '../../config/path';
import { validateSnapshot } from '../../lib/map/validate';
import Matrix from '../../lib/matrix';
import { readJsonFile } from '../../lib/readJsonFile';
import useMapPageGenerator from './hooks/useMapPageGenerator';
import MapGeneratorOptions from './MapGeneratorOptions';

import type { ArtworkProps } from '../../components/Map/Artwork';
import type { MapSnapshot } from '../../lib/map';

// -- Types --------------------------------------------------------------------

type Mode = 'draw' | 'generate';

// -- Config -------------------------------------------------------------------

/** Import map button styles. */
const importMapButtonSx = { mb: 2 };

// -- Memoized Components ------------------------------------------------------

const StaticMapMemo = memo(StaticMap);
const EmptyStateContentMemo = memo(EmptyStateContent);

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map generator.
 */
export default function MapPage() {
  const [ mode, setMode ] = useState<Mode>('generate');
  const [ mapSnapshot, setMapSnapshot ] = useState<MapSnapshot>();
  const [ importError, setImportError ] = useState<string>();
  const { clearChangesFlag, flagChanges } = useContext(UnsavedChangesContext);

  const hasSnapshot = Boolean(mapSnapshot);

  useEffect(() => {
    hasSnapshot ? flagChanges() : clearChangesFlag();
  }, [ hasSnapshot, flagChanges, clearChangesFlag ]);

  /** Handles clearing the map. */
  function onClearMap() {
    setMapSnapshot(undefined);
  }

  /** Handles map import errors. */
  function onImportError(message: string) {
    setImportError(message);
  }

  /** Handles map import success. */
  function onImportSuccess(snapshot: MapSnapshot) {
    try {
      validateSnapshot(snapshot);
    } catch (error) {
      setImportError(error instanceof Error
        ? error.message
        : 'An unidentifiable error occurred importing your map. This is a bug, please report it to AJ.'
      );

      return;
    }

    setMapSnapshot(snapshot);
  }

  /** Toggles draw mode on. */
  function onDrawMode() {
    flagChanges();
    setMode('draw');
  }

  /** Toggles draw mode off. */
  function onExitDrawMode(snapshot?: MapSnapshot) {
    if (!snapshot) {
      clearChangesFlag();
    }

    setMapSnapshot(snapshot);
    setMode('generate');
  }

  return (
    <>
      <Metadata
        description="Instantly generate random D&D dungeon maps, area descriptions, and a lot of loot for those adventurers! Or use the draw tool to create maps from scratch!"
        path={path.maps}
        title="D&D Dungeon Generator"
      />

      <PageContent
        mapSnapshot={mapSnapshot}
        mode={mode}
        onClearMap={onClearMap}
        onDrawMode={onDrawMode}
        onExitDrawMode={onExitDrawMode}
        onImportError={onImportError}
        onImportSuccess={onImportSuccess}
        onSetMapSnapshot={setMapSnapshot}
      />

      {importError &&
        <AlertDialog
          isOpen
          onConfirm={() => setImportError(undefined)}
          title="Invalid map"
        >
          <p>
            Hmm... Our expert Goblin cartographer, Pascal Patchouli, is having
            trouble reading your map file.
          </p>
          <p>Pascal says: <em>{importError}</em></p>
        </AlertDialog>
      }
    </>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders the map generator's empty state, generated map, or draw mode.
 */
function PageContent({
  mapSnapshot,
  mode,
  onClearMap,
  onDrawMode,
  onExitDrawMode,
  onImportError,
  onImportSuccess,
  onSetMapSnapshot,
}: {
  mapSnapshot?: MapSnapshot;
  mode: Mode;
  onClearMap: () => void;
  onDrawMode: () => void;
  onExitDrawMode: (snapshot?: MapSnapshot) => void;
  onImportError: (message: string) => void;
  onImportSuccess: (snapshot: MapSnapshot) => void;
  onSetMapSnapshot: (snapshot?: MapSnapshot) => void;
}) {
  if (mode === 'draw') {
    return (
      <InteractiveMap
        mapSnapshot={mapSnapshot}
        onExit={onExitDrawMode}
      />
    );
  }

  return (
    <Generator
      mapSnapshot={mapSnapshot}
      onClearMap={onClearMap}
      onDrawMode={onDrawMode}
      onImportError={onImportError}
      onImportSuccess={onImportSuccess}
      onSetMapSnapshot={onSetMapSnapshot}
    />
  );
}

/**
 * Renders the map generator.
 */
function Generator({
  mapSnapshot,
  onClearMap,
  onDrawMode,
  onImportError,
  onImportSuccess,
  onSetMapSnapshot,
}: {
  mapSnapshot?: MapSnapshot;
  onClearMap: () => void;
  onDrawMode: () => void;
  onImportError: (message: string) => void;
  onImportSuccess: (snapshot: MapSnapshot) => void;
  onSetMapSnapshot: (snapshot?: MapSnapshot) => void;
}) {
  const { onHideSidebar, onShowSidebar, showSidebar } = useSidebar();

  const generatorProps = useMapPageGenerator({
    onHideSidebar,
    onSetMapSnapshot,
  });

  return (
    <Layout
      sidebar={showSidebar && <MapGeneratorOptions {...generatorProps} />}
    >
      {mapSnapshot
        ? (
          <StaticMapMemo
            mapSnapshot={mapSnapshot}
            onClearMap={onClearMap}
            onEditMap={onDrawMode}
            onShowSidebar={onShowSidebar}
          />
        ) : (
          <EmptyStateContentMemo
            onDrawMap={onDrawMode}
            onGenerate={onShowSidebar ?? generatorProps.onGenerate}
            onImportError={onImportError}
            onImportSuccess={onImportSuccess}
            showDrawButton={!onShowSidebar}
          />
        )
      }
    </Layout>
  );
}

/**
 * Renders the interactive map canvas.
 */
function InteractiveMap({
  mapSnapshot,
  onExit,
}: {
  mapSnapshot?: MapSnapshot;
  onExit: (snapshot?: MapSnapshot) => void;
}) {
  const interactiveMapState = useInteractiveMap(mapSnapshot);
  const mapInfoState = useMapInfo(mapSnapshot);

  const { currentHistoryEntry, title } = interactiveMapState;

  const snapshot: MapSnapshot = {
    title,
    v: appVersion,
    ...mapInfoState.mapInfo,
    ...currentHistoryEntry,
  };

  const {
    onCloseDownloadOptions,
    onDownloadJson,
    onDownloadPng,
    onShowDownloadOptions,
    renderDownloadStage,
    showDownloadOptions,
    stageRef,
  } = useDownloadMap(snapshot);

  const isEmpty = !snapshot.regions?.length;

  /** Exits the map editor, preserving map changes, if any. */
  function onExitMapEditor() {
    isEmpty ? onExit() : onExit(snapshot);
  }

  return (
    <>
      <MapCanvas
        isEmpty={isEmpty}
        onDownloadMap={onShowDownloadOptions}
        onExit={onExitMapEditor}
        {...interactiveMapState}
        {...mapInfoState}
      />

      <DownloadOptions
        isOpen={showDownloadOptions}
        onClose={onCloseDownloadOptions}
        onDownloadJson={onDownloadJson}
        onDownloadPng={onDownloadPng}
      />

      {renderDownloadStage &&
        <DownloadStage
          stageRef={stageRef}
          {...interactiveMapState}
          {...mapInfoState.mapInfo}
        />
      }
    </>
  );
}

/**
 * Renders map generate.
 */
function EmptyStateContent({
  onDrawMap,
  onGenerate,
  onImportError,
  onImportSuccess,
  showDrawButton,
}: {
  onDrawMap: () => void;
  onGenerate: () => void;
  onImportError: (message: string) => void;
  onImportSuccess: (snapshot: MapSnapshot) => void;
  showDrawButton: boolean;
}) {
  return (
    <EmptyState
      description="The random D&D dungeon map generator is a graph paper inspired generator which generates maps, area descriptions, and loot for your adventures!"
      title="Map Generator"
    >
      <Stack
        direction="row"
        mb={4}
        mt={4}
        spacing={4}
      >
        <BigJuicyButton
          icon={MapIcon}
          label="Generate Map"
          onClick={onGenerate}
        />

        {showDrawButton &&
          <BigJuicyButton
            icon={DrawIcon}
            label="Draw Map"
            onClick={onDrawMap}
          />
        }
      </Stack>

      <Tooltip
        arrow
        placement="bottom"
        title={
          <>
            Import a previous session by uploading the “my-dungeon.mw.json”
            file you downloaded fom {appTitle}, where “my-dungeon“ is the name
            of your map.
          </>
        }
      >
        <Button
          component="label"
          sx={importMapButtonSx}
        >
          Import map
          <VisuallyHiddenFileInput
            accept="application/json"
            onChange={(event) => readJsonFile(event, {
              onImportError,
              onImportSuccess,
            })}
          />
        </Button>
      </Tooltip>

      <p className="paragraph muted">
        Configure dungeon, area, and loot settings, then click <em className="emphasize">Generate</em>. Or, click <em className="emphasize">Draw Map</em> above to create from scratch.
      </p>

      <WarningBox component="aside">
        <p>
          Loot and area description generators are still being integrated into the map generator, please hang tight. You can <Link to={path.loot}>generate loot</Link> <WidowFix>on the loot page in  the meantime.</WidowFix>
        </p>
      </WarningBox>
    </EmptyState>
  );
}

/**
 * Renders the static map canvas.
 */
function StaticMap({
  mapSnapshot,
  onClearMap,
  onEditMap,
  onShowSidebar,
}: {
  mapSnapshot: MapSnapshot;
  onClearMap: () => void;
  onEditMap: () => void;
  onShowSidebar?: () => void;
}) {
  const {
    artworkProps,
    snapshot,
  } = useStaticMap(mapSnapshot);

  const {
    onCloseDownloadOptions,
    onDownloadJson,
    onDownloadPng,
    onShowDownloadOptions,
    renderDownloadStage,
    showDownloadOptions,
    stageRef,
  } = useDownloadMap(snapshot);

  return (
    <>
      <StaticMapCanvas
        onClearMap={onClearMap}
        onDownloadMap={onShowDownloadOptions}
        onEditMap={onEditMap}
        onShowSidebar={onShowSidebar}
        {...artworkProps}
      />

      <DownloadOptions
        isOpen={showDownloadOptions}
        onClose={onCloseDownloadOptions}
        onDownloadJson={onDownloadJson}
        onDownloadPng={onDownloadPng}
      />

      {renderDownloadStage &&
        <DownloadStage
          stageRef={stageRef}
          {...artworkProps}
        />
      }
    </>
  );
}

// -- Private Hooks ------------------------------------------------------------

/**
 * Converts a snapshot into `<StaticMap>` props.
 *
 * TDL preserve history of generated maps
 */
function useStaticMap(mapSnapshot: MapSnapshot): {
  artworkProps: ArtworkProps;
  snapshot: MapSnapshot;
} {
  const matrix = new Matrix({ entry: mapSnapshot });

  const {
    getDimensions,
    getInstructions,
  } = matrix;

  const [ matrixWidth, matrixHeight ] = getDimensions();

  const { title = '' } = mapSnapshot;
  const { mapInfo } = useMapInfo(mapSnapshot);

  const snapshot: MapSnapshot = {
    title,
    v: appVersion,
    ...mapInfo,
    ...matrix.getHistoryEntry(),
  };

  const artworkProps: ArtworkProps = {
    ...mapInfo,
    instructions: getInstructions(),
    matrixHeight,
    matrixWidth,
    title,
  };

  return {
    artworkProps,
    snapshot,
  };
}
